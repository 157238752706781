exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".auto_container__3sGE1 {\n  background-color: transparent;\n  color: #0C0C0C;\n  width: 278px; }\n  .auto_container__3sGE1:hover, .auto_container__3sGE1:focus {\n    background-color: #D9B080; }\n\n.auto_input__3RaeX {\n  background-color: transparent;\n  border: none;\n  outline: none; }\n\n.auto_suggestionsContainer__1ve5U {\n  background-color: white;\n  display: block;\n  position: absolute;\n  top: 2rem;\n  width: 278px;\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n  z-index: 2; }\n\n.auto_suggestionsList__3rWC6 {\n  margin: 0;\n  padding: 0;\n  list-style-type: none; }\n\n.auto_suggestion__D58SI {\n  padding: 10px 20px; }\n\n.auto_suggestionHighlighted__wmwR8 {\n  background-color: #D9B080; }\n\n.auto_sectionTitle__bwIJw {\n  background-color: #F8DEC0;\n  padding: 5px 5px; }\n", ""]);

// Exports
exports.locals = {
	"container": "auto_container__3sGE1",
	"input": "auto_input__3RaeX",
	"suggestionsContainer": "auto_suggestionsContainer__1ve5U",
	"suggestionsList": "auto_suggestionsList__3rWC6",
	"suggestion": "auto_suggestion__D58SI",
	"suggestionHighlighted": "auto_suggestionHighlighted__wmwR8",
	"sectionTitle": "auto_sectionTitle__bwIJw"
};