exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".search_outbox__14v2P {\n  display: flex;\n  position: relative;\n  margin: 0 1rem;\n  width: 100%;\n  height: 2rem;\n  border: 1px solid #D9B080;\n  margin-top: 0.5rem;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  border-radius: 1em;\n  color: #D9B080; }\n  .search_outbox__14v2P:hover, .search_outbox__14v2P:active {\n    background-color: #D9B080;\n    cursor: pointer;\n    color: #353535; }\n\n.search_auto__2xzH0 {\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content; }\n\n.search_btn__3EgU- {\n  margin-left: 1rem;\n  border: 2px solid #5e7264;\n  border-radius: 5px;\n  background: #414141; }\n  .search_btn__3EgU-:hover {\n    background: #5e7264; }\n    .search_btn__3EgU-:hover button {\n      color: white; }\n  .search_btn__3EgU- button {\n    background: transparent;\n    border: 0;\n    color: white; }\n\n.search_icon__3WG17 {\n  padding-right: 0.5em; }\n\n@media screen and (max-width: 768px) {\n  .search_outbox__14v2P {\n    display: flex;\n    margin: 0;\n    font-size: .8rem; }\n  .search_auto__2xzH0 {\n    width: 100%;\n    padding: 0 1rem; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "search_outbox__14v2P",
	"auto": "search_auto__2xzH0",
	"btn": "search_btn__3EgU-",
	"icon": "search_icon__3WG17"
};