exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans);", ""]);

// Module
exports.push([module.id, ".base_outbox__2Oj3P {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n\n.base_logo__2JcNX {\n  margin-top: 10%;\n  margin-bottom: 10%;\n  position: center;\n  height: 2em;\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"outbox": "base_outbox__2Oj3P",
	"logo": "base_logo__2JcNX"
};