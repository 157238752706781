exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans);", ""]);

// Module
exports.push([module.id, ".button_outbox__3wHXw {\n  width: 70%;\n  height: 30px;\n  marginBottom: 10px; }\n\n.button_btn__1gz9J {\n  width: 100%;\n  height: 40px;\n  font-family: 'Open Sans', sans-serif;\n  color: #0C0C0C;\n  background: #F8DEC0;\n  border: 0px none;\n  text-transform: uppercase;\n  font-weight: bold; }\n", ""]);

// Exports
exports.locals = {
	"outbox": "button_outbox__3wHXw",
	"btn": "button_btn__1gz9J"
};