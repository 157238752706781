exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".filter_outbox__25JPt {\n  width: 250px;\n  min-width: 100px;\n  background: white; }\n\n.filter_box__b-oKa {\n  display: flex;\n  flex-direction: column; }\n\n.filter_title__2iAJc {\n  font-size: 1.25rem;\n  background: white; }\n\n.filter_title_border__3BbJi {\n  border-bottom: 2px solid #F8DEC0;\n  padding-bottom: .5rem; }\n\n.filter_tags__2sQZ1 {\n  display: flex;\n  flex-wrap: wrap; }\n\n.filter_tag__1lZHw {\n  padding: .2rem;\n  background: rgba(0, 0, 0, 0.1);\n  margin: .2rem;\n  display: flex;\n  flex-wrap: nowrap; }\n\n.filter_tag_content__245jU {\n  margin: 0 .2rem; }\n\n.filter_tag_close__1PXvb {\n  padding: 0 .2rem;\n  border-left: 1px solid white;\n  cursor: pointer; }\n\n.filter_content__2pRAe {\n  display: flex;\n  flex-direction: column;\n  margin-top: 1rem; }\n\n.filter_block__RhHwK {\n  margin-bottom: 1rem; }\n\n.filter_clear_btn__1x70g {\n  border: 1px solid #353535 !important; }\n  .filter_clear_btn__1x70g:hover, .filter_clear_btn__1x70g:active {\n    border: 1px solid #D9B080 !important;\n    color: #D9B080 !important; }\n", ""]);

// Exports
exports.locals = {
	"outbox": "filter_outbox__25JPt",
	"box": "filter_box__b-oKa",
	"title": "filter_title__2iAJc",
	"title_border": "filter_title_border__3BbJi",
	"tags": "filter_tags__2sQZ1",
	"tag": "filter_tag__1lZHw",
	"tag_content": "filter_tag_content__245jU",
	"tag_close": "filter_tag_close__1PXvb",
	"content": "filter_content__2pRAe",
	"block": "filter_block__RhHwK",
	"clear_btn": "filter_clear_btn__1x70g"
};