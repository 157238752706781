exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Comfortaa);", ""]);

// Module
exports.push([module.id, ".checkout_outbox__1hLLT {\n  max-width: 100vw;\n  min-width: 320px;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  background: #f4f4f4;\n  font-size: 1rem; }\n\n.checkout_box__1Wd7l {\n  margin: 12vh 0;\n  display: flex;\n  justify-content: center; }\n\n.checkout_content__AByY4 {\n  width: 60%;\n  background: white;\n  display: flex;\n  flex-direction: column;\n  padding: 2em;\n  margin: 2em; }\n\n.checkout_title__116_S {\n  color: #0C0C0C;\n  font-size: 2em;\n  font-family: 'Comfortaa', cursive; }\n\n.checkout_sub_title__np1NO {\n  color: black;\n  font-size: .5em;\n  display: block;\n  margin: 1% 0; }\n\n.checkout_table__3wqjb {\n  border-top: 1px solid black;\n  border-bottom: 1px solid black;\n  padding: 2%; }\n\n.checkout_prices__36mAG {\n  padding: 2% 0;\n  border-bottom: 1px solid black; }\n\n.checkout_btn__3dg8r {\n  display: flex;\n  justify-content: center;\n  margin-top: 5%; }\n  .checkout_btn__3dg8r button {\n    background-color: #F8DEC0;\n    font-weight: bold;\n    justify-content: center;\n    width: 80%;\n    height: 40px;\n    border: 1px none;\n    border-radius: 5px;\n    color: #0C0C0C;\n    text-transform: uppercase;\n    font-family: 'Comfortaa', cursive; }\n    .checkout_btn__3dg8r button:hover {\n      background-color: #D9B080;\n      color: #EEEEEE; }\n\n@media screen and (max-width: 768px) {\n  .checkout_outbox__1hLLT {\n    font-size: .7rem; }\n  .checkout_content__AByY4 {\n    width: 80%; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "checkout_outbox__1hLLT",
	"box": "checkout_box__1Wd7l",
	"content": "checkout_content__AByY4",
	"title": "checkout_title__116_S",
	"sub_title": "checkout_sub_title__np1NO",
	"table": "checkout_table__3wqjb",
	"prices": "checkout_prices__36mAG",
	"btn": "checkout_btn__3dg8r"
};