exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Comfortaa);", ""]);

// Module
exports.push([module.id, ".checkoutSuccess_outbox__2tNsJ {\n  margin-top: 80px;\n  width: 100vw;\n  min-height: 100vh; }\n\n.checkoutSuccess_box__la7Ir {\n  width: 100%;\n  padding-top: 12vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n\n.checkoutSuccess_checkMark__1exJ1 {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n  .checkoutSuccess_checkMark__1exJ1 .checkoutSuccess_pic__3Tfor {\n    width: 100px;\n    height: 100px;\n    height: 100%; }\n    .checkoutSuccess_checkMark__1exJ1 .checkoutSuccess_pic__3Tfor img {\n      width: 100%; }\n\n.checkoutSuccess_text__2vwjA {\n  font: 3rem sans-serif;\n  font-weight: bold;\n  text-align: center;\n  position: relative;\n  z-index: 1;\n  margin-top: 0.5em;\n  margin-bottom: 10px; }\n  .checkoutSuccess_text__2vwjA:before {\n    border-top: 0.5em solid #F8DEC0;\n    content: \"\";\n    margin: 0;\n    position: absolute;\n    top: 60%;\n    left: -2.5%;\n    right: 0;\n    bottom: 0;\n    width: 105%;\n    z-index: -1; }\n\n.checkoutSuccess_btn__1913q {\n  display: flex;\n  justify-content: flex-end;\n  font-family: 'Comfortaa', cursive;\n  margin-top: 3em; }\n  .checkoutSuccess_btn__1913q button {\n    background: white;\n    letter-spacing: 2px;\n    height: 40px;\n    color: #D9B080;\n    border: 0px none;\n    border-radius: 5px; }\n    .checkoutSuccess_btn__1913q button FontAwesomeIcon {\n      padding-right: 2px; }\n    .checkoutSuccess_btn__1913q button:hover, .checkoutSuccess_btn__1913q button:active {\n      color: #0C0C0C; }\n\n@media screen and (max-width: 768px) {\n  .checkoutSuccess_text__2vwjA {\n    font-size: 1.5em; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "checkoutSuccess_outbox__2tNsJ",
	"box": "checkoutSuccess_box__la7Ir",
	"checkMark": "checkoutSuccess_checkMark__1exJ1",
	"pic": "checkoutSuccess_pic__3Tfor",
	"text": "checkoutSuccess_text__2vwjA",
	"btn": "checkoutSuccess_btn__1913q"
};