exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".table_outbox__i6SH_ {\n  font-size: 1rem; }\n\n.table_id__1b_hZ {\n  font-size: 0.7em; }\n\n.table_pic_outbox__Sjh5- {\n  max-width: 6em;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .table_pic_outbox__Sjh5- img {\n    max-width: calc(100% - 1.3em); }\n\n.table_title_outbox__2CRU0 {\n  display: flex;\n  flex-direction: column;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  margin: 0; }\n\n.table_title__3ejko {\n  word-wrap: none !important;\n  min-width: 6em;\n  max-width: 8em;\n  margin-bottom: 1em; }\n\n.table_qty_outbox__1Ebuq {\n  display: flex;\n  flex-wrap: nowrap; }\n\n.table_btn__3IR_w {\n  padding: 0 0.5em;\n  margin: 0 0.5em; }\n\n@media screen and (max-width: 768px) {\n  .table_outbox__i6SH_ {\n    font-size: .7rem; }\n  .table_pic_outbox__Sjh5- {\n    font-size: .6rem; }\n  .table_btn__3IR_w {\n    font-size: .5rem; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "table_outbox__i6SH_",
	"id": "table_id__1b_hZ",
	"pic_outbox": "table_pic_outbox__Sjh5-",
	"title_outbox": "table_title_outbox__2CRU0",
	"title": "table_title__3ejko",
	"qty_outbox": "table_qty_outbox__1Ebuq",
	"btn": "table_btn__3IR_w"
};