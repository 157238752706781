exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Comfortaa);", ""]);

// Module
exports.push([module.id, ".shoppingBag_outbox__2AHqc {\n  min-width: -webkit-max-content;\n  min-width: -moz-max-content;\n  min-width: max-content;\n  min-width: 386px;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  background: #f4f4f4;\n  font-size: 1rem; }\n\n.shoppingBag_box__1f8YI {\n  width: 100%;\n  margin-top: 12vh;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n\n.shoppingBag_content__1RMP1 {\n  padding: 3%;\n  background: white;\n  min-width: -webkit-max-content;\n  min-width: -moz-max-content;\n  min-width: max-content;\n  justify-content: center;\n  align-items: center; }\n  .shoppingBag_content__1RMP1 img {\n    display: block;\n    margin: 10px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 2em;\n    margin-bottom: 2em;\n    width: 50%; }\n\n.shoppingBag_title__2eAnt {\n  color: #0C0C0C;\n  text-align: center;\n  font-size: 2rem;\n  font-weight: bold;\n  font-family: 'Comfortaa', cursive; }\n\n.shoppingBag_table__G7FIy {\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content; }\n\n.shoppingBag_process_box__1jkJe {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end; }\n\n.shoppingBag_total__3Aety {\n  text-align: right;\n  font-size: 20px;\n  font-weight: bold; }\n\n.shoppingBag_btn_checkout__3qVAL {\n  width: 100%;\n  justify-content: center;\n  margin-top: 5%; }\n  .shoppingBag_btn_checkout__3qVAL button {\n    background-color: #F8DEC0;\n    font-weight: bold;\n    justify-content: center;\n    width: 100%;\n    height: 40px;\n    border: 1px none;\n    border-radius: 5px;\n    color: #0C0C0C;\n    text-transform: uppercase;\n    font-family: 'Comfortaa', cursive; }\n    .shoppingBag_btn_checkout__3qVAL button:hover {\n      background-color: #D9B080;\n      color: #EEEEEE; }\n", ""]);

// Exports
exports.locals = {
	"outbox": "shoppingBag_outbox__2AHqc",
	"box": "shoppingBag_box__1f8YI",
	"content": "shoppingBag_content__1RMP1",
	"title": "shoppingBag_title__2eAnt",
	"table": "shoppingBag_table__G7FIy",
	"process_box": "shoppingBag_process_box__1jkJe",
	"total": "shoppingBag_total__3Aety",
	"btn_checkout": "shoppingBag_btn_checkout__3qVAL"
};