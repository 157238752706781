exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto);", ""]);

// Module
exports.push([module.id, ".loginsignin_outbox__3cLl8 {\n  width: 100%;\n  height: 100%;\n  float: left; }\n  .loginsignin_outbox__3cLl8::after {\n    content: \"\";\n    display: table;\n    clear: both; }\n\n.loginsignin_login_image__2M6EG {\n  float: left;\n  width: 50%; }\n\n.loginsignin_login_form__31ZMt {\n  border: 1px solid black;\n  float: left;\n  width: 50%; }\n\n.loginsignin_redirect__3wugm {\n  color: #D9B080; }\n\n.loginsignin_box__2XnId {\n  min-width: 300px;\n  height: auto; }\n", ""]);

// Exports
exports.locals = {
	"outbox": "loginsignin_outbox__3cLl8",
	"login_image": "loginsignin_login_image__2M6EG",
	"login_form": "loginsignin_login_form__31ZMt",
	"redirect": "loginsignin_redirect__3wugm",
	"box": "loginsignin_box__2XnId"
};