exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans);", ""]);

// Module
exports.push([module.id, ".formInput_outbox__32GHa {\n  display: flex;\n  flex-direction: column;\n  width: 70%;\n  margin-bottom: 5px; }\n\n.formInput_label__5YKnY {\n  font-family: 'Open Sans', sans-serif;\n  font-weight: bold; }\n  .formInput_label__5YKnY label {\n    margin: 0; }\n\n.formInput_input__2QgSh {\n  width: 100%;\n  height: 40px;\n  margin-bottom: 30px; }\n  .formInput_input__2QgSh input {\n    width: 100%;\n    height: 100%;\n    border: 1px solid grey;\n    padding: 0 0.5rem; }\n    .formInput_input__2QgSh input::-webkit-input-placeholder {\n      color: #353535; }\n    .formInput_input__2QgSh input::-moz-placeholder {\n      color: #353535; }\n    .formInput_input__2QgSh input::-ms-input-placeholder {\n      color: #353535; }\n    .formInput_input__2QgSh input::placeholder {\n      color: #353535; }\n\n.formInput_errMsg__3uUYw {\n  color: red;\n  font-size: 15px; }\n", ""]);

// Exports
exports.locals = {
	"outbox": "formInput_outbox__32GHa",
	"label": "formInput_label__5YKnY",
	"input": "formInput_input__2QgSh",
	"errMsg": "formInput_errMsg__3uUYw"
};