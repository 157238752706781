exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".subtotal_outbox__1xYXU {\n  display: flex;\n  flex-direction: column;\n  font-size: 1em; }\n\n.subtotal_subtotals__2fueZ {\n  display: flex;\n  flex-direction: column; }\n\n.subtotal_row__22Vxn {\n  display: flex;\n  justify-content: space-between;\n  font-size: 1em;\n  color: grey;\n  margin: 0.1em 0; }\n\n.subtotal_total__Frpx6 {\n  font-size: 1.2em;\n  color: black;\n  font-weight: 500; }\n\n@media screen and (max-width: 768px) {\n  .subtotal_outbox__1xYXU {\n    font-size: .7rem; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "subtotal_outbox__1xYXU",
	"subtotals": "subtotal_subtotals__2fueZ",
	"row": "subtotal_row__22Vxn",
	"total": "subtotal_total__Frpx6"
};