exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "@media screen and (max-width: 768px) {\n  .sub_selection_outbox__2pWKV {\n    width: 100%;\n    min-height: -webkit-max-content;\n    min-height: -moz-max-content;\n    min-height: max-content;\n    padding: 0 2rem; }\n  .sub_selection_box__3h_3j {\n    z-index: 2;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    color: black;\n    background: rgba(125, 125, 125, 0.2);\n    border-radius: 8px;\n    flex-wrap: wrap; }\n  .sub_selection_content__25U0o {\n    height: 3rem;\n    display: flex;\n    align-items: center;\n    font-size: .8rem;\n    cursor: pointer;\n    padding: 2px .5rem;\n    white-space: nowrap; }\n  .sub_selection_selected__gEzyT {\n    border-bottom: 1px solid blue; } }\n\n@media screen and (max-width: 425px) {\n  .sub_selection_content__25U0o {\n    font-size: .5rem;\n    height: 1rem; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "sub_selection_outbox__2pWKV",
	"box": "sub_selection_box__3h_3j",
	"content": "sub_selection_content__25U0o",
	"selected": "sub_selection_selected__gEzyT"
};