exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Comfortaa);", ""]);

// Module
exports.push([module.id, ".table_outbox__3lMNs {\n  display: flex;\n  flex-direction: column; }\n\n.table_row__3lLI2 {\n  display: flex;\n  justify-content: space-between;\n  margin: 3% 0; }\n\n.table_pic__2nMWm {\n  max-width: 6em;\n  margin-right: 1em;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .table_pic__2nMWm img {\n    max-width: 70%;\n    border-radius: 10%; }\n\n.table_title__18mFW {\n  margin: 0 .3em;\n  font-size: 1em;\n  font-family: sans-serif; }\n\n.table_price__3Nvmu {\n  margin-left: auto;\n  font-size: 1em;\n  font-family: 'Comfortaa', cursive;\n  font-weight: 600; }\n\n@media screen and (max-width: 768px) {\n  .table_outbox__3lMNs {\n    font-size: .7rem; }\n  .table_pic__2nMWm {\n    font-size: .6rem; }\n  .table_price__3Nvmu {\n    font-size: .5rem; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "table_outbox__3lMNs",
	"row": "table_row__3lLI2",
	"pic": "table_pic__2nMWm",
	"title": "table_title__18mFW",
	"price": "table_price__3Nvmu"
};