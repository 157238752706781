exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboard_outbox__1Z8DY {\n  min-width: 320px;\n  min-height: 568px;\n  max-width: 100vw;\n  max-height: 100vh; }\n\n.dashboard_box__28Cgd {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 80px;\n  max-width: 100%; }\n\n.dashboard_filter__33JdN {\n  margin: 1rem; }\n\n.dashboard_products__2WVnh {\n  display: flex;\n  width: 80%;\n  flexWrap: wrap !important; }\n\n.dashboard_product__34dYb {\n  min-width: 150px; }\n\n@media screen and (max-width: 768px) {\n  .dashboard_box__28Cgd {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    margin-top: 8vh; }\n  .dashboard_filter__33JdN {\n    margin: 0;\n    width: 100%; }\n  .dashboard_products__2WVnh {\n    width: 100%; } }\n", ""]);

// Exports
exports.locals = {
	"outbox": "dashboard_outbox__1Z8DY",
	"box": "dashboard_box__28Cgd",
	"filter": "dashboard_filter__33JdN",
	"products": "dashboard_products__2WVnh",
	"product": "dashboard_product__34dYb"
};