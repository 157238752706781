exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Comfortaa);", ""]);

// Module
exports.push([module.id, ".variants_color_title___0lRJ {\n  color: black;\n  font-weight: bold;\n  font-family: 'Comfortaa', cursive;\n  margin-bottom: 0.5rem; }\n\n.variants_color_name__3gxDC {\n  color: grey;\n  font-family: 'Comfortaa', cursive; }\n\n.variants_sizes__2BdP1 {\n  width: 100%;\n  color: black;\n  font-weight: bold;\n  font-family: 'Comfortaa', cursive; }\n\n.variants_size_title__2JAJq {\n  margin-bottom: 0.5rem; }\n\n.variants_color_pic__IF_hJ {\n  width: 100%;\n  height: 10%;\n  margin-bottom: 1rem; }\n  .variants_color_pic__IF_hJ img {\n    width: 10%;\n    margin-right: 0.7rem;\n    cursor: pointer; }\n\n@media (max-width: 767.98px) {\n  .variants_color_title___0lRJ {\n    font-size: 0.75rem;\n    margin-bottom: 0.25rem; }\n  .variants_color_name__3gxDC {\n    font-size: 0.5rem; }\n  .variants_size_title__2JAJq {\n    font-size: 0.75rem;\n    margin-bottom: 0.25rem; }\n  .variants_color_pic__IF_hJ {\n    margin-bottom: 0.5rem; }\n    .variants_color_pic__IF_hJ img {\n      width: 20%; } }\n", ""]);

// Exports
exports.locals = {
	"color_title": "variants_color_title___0lRJ",
	"color_name": "variants_color_name__3gxDC",
	"sizes": "variants_sizes__2BdP1",
	"size_title": "variants_size_title__2JAJq",
	"color_pic": "variants_color_pic__IF_hJ"
};