exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".product_card__3eee6 {\n  min-width: 150px;\n  border: none;\n  margin: 0;\n  cursor: pointer; }\n  .product_card__3eee6:hover, .product_card__3eee6:active {\n    box-shadow: 2px 2px 4px #353535; }\n\n@media (max-width: 767.98px) {\n  .product_title__7CSCs {\n    font-size: 15px; }\n  .product_subtitle__1em4o {\n    font-size: 10px !important; }\n  .product_color__a18EJ {\n    font-size: 10px; } }\n", ""]);

// Exports
exports.locals = {
	"card": "product_card__3eee6",
	"title": "product_title__7CSCs",
	"subtitle": "product_subtitle__1em4o",
	"color": "product_color__a18EJ"
};